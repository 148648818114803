import React, { useRef, useState } from "react";
import { Styling } from "./styled.js";
import { GatsbyImage } from "gatsby-plugin-image";

export default function CampaignsSection2({ s2LogosHeading, s2FeaturedLogos }) {
  return (
    <Styling>
      <div className="featuredOn">
        <div className="featuredlogos">
          <div className="heading">{s2LogosHeading}</div>
          <div className="grid">
            {s2FeaturedLogos.map((logo) => (
              <GatsbyImage image={logo.logo.localFile.childImageSharp.gatsbyImageData} objectFit="contain" />
            ))}
          </div>
        </div>
      </div>
    </Styling>
  );
}
