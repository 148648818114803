import styled from "styled-components";
import Arrow from "../../../../../images/arrow_bullet.svg";

const Styling = styled.div`
  width: 100%;
  background: var(--black);
  color: var(--white);
  padding: 4rem 2rem;
  display: flex;
  flex-wrap: wrap;

  .featuredOn {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 4rem;

    @media only screen and (min-width: 620px) {
      padding: 4rem 0;
    }

    .featuredHeading {
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      font-size: 2.2rem;
    }

    .featuredlogos {
      margin: 0 auto;
      max-width: 1280px;

      .heading {
        margin-bottom: 4rem;
      }

      .grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 2rem;

        @media only screen and (max-width: 600px) {
          grid-template-columns: repeat(2, 1fr);
        }

        img {
          max-height: 80px;
          max-width: 200px;
        }
      }
    }
  }

  .main-content {
    width: 100%;
    gap: 2rem;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (min-width: 620px) {
      display: grid;
    }

    .mainHeading {
      text-transform: uppercase;
      ${({ theme }) => theme.tomato("regular")}
      text-transform:uppercase;
      /* font-size: clamp(5rem, 3.5vw, 12rem); */
      letter-spacing: -0.07em;
      margin-bottom: 2rem;
      font-size: 4rem;
      line-height: 4rem;
      margin: 0 auto 1rem auto;
      max-width: 650px;
      width: 100%;
      text-align: center;

      @media only screen and (max-width: 620px) {
        font-size: 3rem;
        line-height: 3rem;
      }

      strong {
        ${({ theme }) => theme.tomato("bold")}
      }
    }

    .text {
      width: 100%;
      margin-bottom: 4rem;
      text-align: center;
    }

    .list {
      margin: 0 auto;
      display: grid;
      width: 100%;
      /* padding: 0 4rem 0 0; */
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: 2rem;

      @media only screen and (min-width: 768px) {
        gap: 4rem;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      }

      @media only screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        max-width: 1024px;
      }

      li {
        display: grid;
        gap: 4rem;

        @media only screen and (min-width: 768px) {
          gap: 4rem 8rem;
        }
        .icon {
          max-width: 75px;
          margin: 0 auto;

          @media only screen and (min-width: 768px) {
            margin: 0 auto 1rem auto;
          }
        }

        .text {
          width: 100%;
          display: grid;
          gap: 1rem;

          h3 {
            font-size: 2.4rem;
            text-transform: uppercase;
          }

          p {
            line-height: 1.5em;
          }
        }
      }
    }
  }
  .capsule_button {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    margin-bottom: 4rem;

    @media only screen and (max-width: 768px) {
      margin-bottom: 0;
    }
  }
`;

export { Styling };
