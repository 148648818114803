import React, { useRef, useState } from "react";
import { Styling } from "./styled.js";
import { GatsbyImage } from "gatsby-plugin-image";
import SignupButton from "../../SignupButton.js";

export default function CampaignsSection1({ s1LogosHeading, s1FeaturedLogos }) {
  return (
    <Styling>
      <div className="featuredOn">
        <div className="featuredHeading">{s1LogosHeading}</div>
        <div className="featuredlogos">
          {s1FeaturedLogos.map((logo) => (
            <GatsbyImage image={logo.logo.localFile.childImageSharp.gatsbyImageData} objectFit="contain" />
          ))}
        </div>
      </div>
      {/* <SignupButton externalSalesUrl={externalSalesUrl} /> */}
    </Styling>
  );
}
