import React, { useRef, useState } from "react";
import { Styling } from "./styled.js";
import parse from "html-react-parser";
import Play from "../../../../../images/play.inline.svg";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import TrustBox from "../../TrustBox.js";
import SignupButton from "../../SignupButton.js";

export default function CampaignsHero({ video, logo1, logo2, introVideoObjectFit, title, introText, darkLightMode, index, slug }) {
  const trailerVid = useRef();

  return (
    <Styling id={`section_${index + 1}`} darkLightMode={darkLightMode} introVideoObjectFit={introVideoObjectFit}>
      <Helmet>
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
      </Helmet>
      <div className="video-wrapper">
        <div className={`play paused`}>
          <Link to={`${slug}-2`}>
            <Play />
          </Link>
        </div>
        <video playsInline ref={trailerVid} src={video}></video>
      </div>
      <div className="info-right">
        <div className="logos">
          {logo1 && <GatsbyImage image={logo1?.localFile?.childImageSharp?.gatsbyImageData} alt={logo1.altText} objectFit="contain" />}
          {logo2 && <GatsbyImage image={logo2?.localFile?.childImageSharp?.gatsbyImageData} alt={logo2.altText} objectFit="contain" />}
        </div>
        <div className="info">
          <h1 className="main-title">{parse(title)}</h1>
          {introText !== undefined && <div className="intro-text">{parse(introText)}</div>}
        </div>
        <div className="buttons">
          <Link to={`${slug}-2`} className="fakeButton button capsule_button red artist-development-signup-button">
            Sign up for a free consultation
          </Link>
        </div>

        <div className="trustPilot">
          <TrustBox />
        </div>
      </div>
    </Styling>
  );
}
