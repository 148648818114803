import React from "react";
import { graphql } from "gatsby";
import CampaignsHero from "../components/page-components/Campaigns/Page1/CampaignsHero";
import CampaignsSection1 from "../components/page-components/Campaigns/Page1/CampaignsSection1";
import CampaignsSection2 from "../components/page-components/Campaigns/Page1/CampaignsSection2";
import { useLocation } from "@reach/router";

export default function Campaigns({ data }) {
  const { pageData } = data.wpCampaign;
  const location = useLocation();

  return (
    <>
      <CampaignsHero video={pageData.videoUrl} logo1={pageData.logo1} logo2={pageData.logo2} introVideoObjectFit={"cover"} title={pageData.heading} introText={pageData.introText} darkLightMode={"dark"} index={1} slug={location.pathname} />
      <CampaignsSection1 s1LogosHeading={pageData.s1LogosHeading} s1FeaturedLogos={pageData.s1FeaturedLogos} />
      <CampaignsSection2 s2LogosHeading={pageData.s2LogosHeading} s2FeaturedLogos={pageData.s2FeaturedLogos} />
    </>
  );
}

export const query = graphql`
  query ($id: String!) {
    wpCampaign(id: { eq: $id }) {
      seo {
        metaDesc
        title
      }
      pageData: acf_campaigns {
        # Hero
        heading
        introText
        videoUrl
        logo1 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        logo2 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        # Section 1
        s1LogosHeading
        s1FeaturedLogos {
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        # Section 2
        s2LogosHeading
        s2FeaturedLogos {
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
